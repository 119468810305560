import { api } from './api';
const _ = require('lodash');

export const createBotendienstOrder = async payload => {
  try {
    const response = await api.post(`/orders/rx`, payload);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getBotendienstOrders = async query => {
  const preparedQuery = _.pickBy(query, _.identity);
  try {
    const response = await api.get(`/orders/rx`, {
      params: preparedQuery,
    });
    return response.data;
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
};

export const getBotendienstOrdersExport = async query => {
  try {
    const response = await api.get(`/orders/rx`, {
      headers: {
        Accept: 'text/csv',
      },
      params: query,
      responseType: 'blob',
    });
    const headers = response.headers;
    const contentDisposition = headers['content-disposition'];

    return { data: response.data, fileName: contentDisposition };
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
};
