import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/navbar.css';
import { MdArrowBackIosNew } from 'react-icons/md';
function OrderHeading({ orderIdIdentifier }) {
  const history = useHistory();

  return (
    <nav id="navbar">
      <div onClick={() => history.goBack()} className="back-button">
        <MdArrowBackIosNew size={30} />
        <p className="nav-title">Zurück</p>
      </div>
      <div className="title-container">
        <p className="order-title"><span>ORDER ID:</span> #{orderIdIdentifier}</p>
      </div>
    </nav>
  );
}

export default OrderHeading;
