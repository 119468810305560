import React, { useRef, useEffect, useState } from 'react';
import CustomDropDown from './CustomDropDown';
import SubstituteItem from './SubstituteItem';
function OrderItemTable(props) {
  const { items, setItems, addSubstitute, deleteSubstitute } = props;
  const selectOptions = useRef(null);
  const [isPharmSelected, setIsPharmSelected] = useState([]);

  function disabledCheck() {
    return props.status === 'pending' ? false : true;
  }

  const saveComments = (value, idx) => {
    items[idx].comment = value || '';
    setItems([...items]);
  };

  useEffect(() => {
    if (selectOptions.current != null) {
      selectOptions.current.style.display = 'none';
    }
  }, [selectOptions.current]);

  useEffect(() => {
    items.forEach((item, idx) => {
      isPharmSelected[idx] = false;
      if (item.status === 'wrong_info') {
        isPharmSelected[idx] = true;
      }
      setIsPharmSelected([...isPharmSelected]);
    });
  }, [items]);

  return (
    <div>
      <table className="table" frame="hsides" rules="rows" border="1">
        <tbody>
          <tr className="table-row table-heading">
            <th className="table-item">Name</th>
            <th className="table-item">PZN</th>
            <th className="table-item">Preis</th>
            <th className="table-item">Packung</th>
            <th className="table-item">Anzahl</th>
            <th className="table-item">Status</th>
          </tr>
          {items.map((item, idx) => {
            const { quantity, package_size, pzn, name, status, price } = item;
            return (
              <div>
                <tr key={idx} className="table-row light-gray">
                  <td className="table-item">
                    <div className="table-in">
                      <div>
                        <p>{name}</p>
                      </div>
                    </div>
                  </td>
                  <td className="table-item">
                    <div className="table-in">
                      <div>
                        <p>{pzn}</p>
                      </div>
                    </div>
                  </td>
                  <td className="table-item">
                    <div className="table-in">
                      <div>
                        <p>{price ? price + ' €' : ''}</p>
                      </div>
                    </div>
                  </td>
                  <td className="table-item">
                    <div className="table-in">
                      <div>
                        <p style={{ color: 'red' }}>{package_size}</p>
                      </div>
                    </div>
                  </td>
                  <td
                    className="table-item"
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      className="table-in"
                      style={{
                        width: '70px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'red',
                        borderRadius: '8px',
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                    >
                      <p>{`${quantity}x`}</p>
                    </div>
                  </td>
                  <td className="table-item">
                    <div className="table-in">
                      <div>
                        <CustomDropDown
                          item={item}
                          index={idx}
                          itemStatusHandler={props.itemStatusHandler}
                          disabledCheck={disabledCheck}
                          deleteSubstitute={deleteSubstitute}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Substitut beigelegt Field */}
                {status === 'substitute' && (
                  <SubstituteItem
                    index={idx}
                    addSubstitute={addSubstitute}
                    item={item}
                    disabledCheck={disabledCheck}
                  />
                )}
                {isPharmSelected[idx] && (
                  <tr
                    className="table-row"
                    style={{ backgroundColor: 'rgba(196, 196, 196, 0.12)' }}
                  >
                    <td className="table-item">
                      <textarea
                        placeholder="Kommentar"
                        defaultValue={item.comment}
                        onChange={event => saveComments(event.target.value, idx)}
                      />
                    </td>
                  </tr>
                )}
              </div>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OrderItemTable;
