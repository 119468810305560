import React, { useEffect, useState } from 'react';
import './App.css';
import Routes from './pages/routes/Routes';
import { getPharmacyProfile } from './services/pharmacy';
import ZohoSalesIQ from './components/ZohoSalesIQ';
import { savePharmacyToLocal } from './utils/pharmacy';

const App = () => {
  const pathname = window.location.pathname;
  const [pharmacy, setPharmacy] = useState({});
  const [initiateZoho, setInitiateZoho] = useState(false);

  const init = async () => {
    if (pathname !== '/login') {
      try {
        await getPharmacyProfile().then(response => {
          const pharmacyResponse = JSON.stringify(response);
          setPharmacy(pharmacyResponse);
          savePharmacyToLocal(pharmacyResponse);

          setInitiateZoho(true);
        });
      } catch (e) {
        console.error('Error fetching pharmacy profile: ', e);
        setInitiateZoho(true);
      }
    } else {
      setInitiateZoho(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <Routes />

      {initiateZoho && <ZohoSalesIQ pharmacy={pharmacy} />}
    </div>
  );
};

export default App;
