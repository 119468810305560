import { api } from "./api";

export const loginPharmacy = async (body) => {
  try {
    const response = await api.post(`/auth/login`, {
      email: body.email,
      password: body.password,
    });
    return response.data;
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
}
