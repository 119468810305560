import React from 'react';
import { handleOrderLabelDownload } from '../../helper/order';

function OrderLabel({ order, style = {} }) {
  if (!order) return null;

  return (
    <button
      onClick={() => handleOrderLabelDownload(order)}
      style={{
        display: 'flex',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#03b2c3',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: 0,
        ...style,
      }}
    >
      <i className="fa fa-file-pdf-o" style={{ marginRight: '4px' }}></i>
      {order?.order_id_identifier}
    </button>
  );
}

export default OrderLabel;
