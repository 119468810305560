import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderItems } from '../../services/orders';
import Address from '../../components/common/address/Address';
import OrderHeading from './OrderHeading';
import OrderItemTable from './OrderItemTable';
import Rider from '../../components/common/rider/Rider';

function OrderHistoryItemPage() {
  let { order_id_mongo } = useParams();
  const [items, setItems] = useState([]);
  const [check, setCheck] = useState(false);
  const [pharmacyNotes, setPharmacyNotes] = useState('');
  const [firstaOrderId, setFirstaOrderId] = useState();
  const [orderIdIdentifier, setOrderIdIdentifier] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [prescription, setPrescription] = useState('');
  const [orderId, setOrderId] = useState('');
  const [order, setOrder] = useState(null);

  useEffect(async () => {
    let response = await getOrderItems(order_id_mongo);
    setItems(prevState => response.items);
    setOrder(response?.order);
    setFirstaOrderId(prevState => response.order.order_id);
    if (response.order.order_id_identifier) {
      setOrderIdIdentifier(prevState => response.order.order_id_identifier);
    } else {
      setOrderIdIdentifier(prevState => response.order.order_id);
    }
    setPharmacyNotes(prevState => response.order.pharmacy_notes);
    setOrderStatus(prevState => response.order.status);
    setCheck(response.order.customer_contacted);
    if (response.order.prescription) {
      setPrescription(prevState => response.order.prescription);
    }
    if (response.order.order_id) {
      setOrderId(prevState => response.order.order_id);
    }
  }, []);

  function clickHandler(e) {
    const index = e.target.value;
    let newItem = [...items];
    newItem[index].name = 'test';
    setItems(newItem);
  }

  function handleCheck(e) {
    let newCheck = !check;
    setCheck(newCheck);
  }

  async function handlePharmacyNotes(e) {
    let newNotes = pharmacyNotes;
    newNotes = e.target.value;
    setPharmacyNotes(prevState => newNotes);
  }

  const handleDownload = () => {
    window.open(`/api/v1/orders/${orderId}/prescription`);
  };

  return (
    <div>
      <OrderHeading orderIdIdentifier={orderIdIdentifier} />
      <br />
      <Address order={order} order_id={order_id_mongo} />
      <Rider order_id={order_id_mongo} />
      <br />
      <div className="btn-container">
        {prescription && (
          <button onClick={handleDownload} target="_blank" className="btn-item">
            Rezept herunterladen
          </button>
        )}
      </div>
      <div>
        <OrderItemTable
          clickHandler={clickHandler}
          items={items}
          orderId={order_id_mongo}
          status={orderStatus}
        />
      </div>
      <br />
      <div className="comment">
        <textarea
          className="comment-input-box"
          type="text"
          value={pharmacyNotes}
          placeholder="Anmerkungen an GoPuls:"
          onChange={handlePharmacyNotes}
          disabled={!check}
        />
      </div>
      <br />
      <div className="confirmation-body">
        <div className="confirmation-container row middle-md center-md">
          <label className="form-control  mr-10">Kunde wurde von uns kontaktiert</label>
          <span></span>
          <div className="form-control mr-10">
            <input
              type="checkbox"
              checked={check}
              name="checkbox"
              className="confirmation-checkbox"
            />
            <label>Ja</label>
          </div>
          <span></span>
          <div className="form-control">
            <input
              type="checkbox"
              checked={!check}
              name="checkbox"
              className="confirmation-checkbox"
            />
            <label>Nein</label>
          </div>
        </div>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <button class="submit_btn disabled_btn" disabled={!check} onClick={clickHandler}>
          Erfolgreich abgeschlossen
        </button>
      </div>
    </div>
  );
}

export default OrderHistoryItemPage;
