import React, { useEffect, useState } from 'react';
import { getUserByOrder } from '../../../services/users';
import { format } from 'date-fns';
import { getDeliveryPartnerUrl } from '../../../helper/order';
import OrderLabel from '../../../pages/orders/OrderLabel';

function Address({ order_id, order }) {
  const [userDetails, setUserDetails] = useState({});

  useEffect(async () => {
    let user = await getUserByOrder(order_id);
    setUserDetails(prevState => user);
  }, []);

  const { name, address, phone, email } = userDetails;

  if (!order) return null;

  return (
    <div>
      <div className="delivery-provider">
        <p>
          <img
            className="delivery-partner"
            src={getDeliveryPartnerUrl(order.delivery_partner)}
            alt="delivery_partner"
          />
        </p>
        {order.order_start_time && order.order_end_time && (
          <p className="flex">
            <span>Lieferzeitfenster:&nbsp;</span>
            <span>
              {format(new Date(order.order_start_time), 'HH:mm')} -{' '}
              {format(new Date(order.order_end_time), 'HH:mm')} <br />
              {format(new Date(order.order_start_time), 'dd.MM.yyyy')}
            </span>
          </p>
        )}
      </div>
      <div className="address-body">
        <div className="address-container">
          <p>{name}</p>
          <p>{address} </p>
        </div>
        <div className="address-container">
          <p>Tel.: {phone}</p>
          <p>E-Mail: {email}</p>
          {order.order_properties && (
            <div>
              <p>User Group: {order.order_properties.userGroup || 'Not Available'}</p>
              <p>
                Order For Self:{' '}
                {order.order_properties.hasOwnProperty('orderForSelf')
                  ? order.order_properties.orderForSelf
                    ? 'Yes'
                    : 'No'
                  : 'Not Available'}
              </p>
            </div>
          )}
        </div>
        <div className="label-container">
          <OrderLabel order={order} style={{ fontSize: 20 }} />
        </div>
      </div>
    </div>
  );
}

export default Address;
