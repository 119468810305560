import React, { useState, useEffect } from 'react';
import { loginPharmacy } from '../../services/auth';
import { getPharmacyProfile } from '../../services/pharmacy';
import Cookies from 'universal-cookie';
import Logo from '../../images/firstALogo.png';
import { headerLogo } from '../../utils/images';
import { savePharmacyToLocal } from '../../utils/pharmacy';
const cookies = new Cookies();

function LoginPage() {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    const isAuthenticated = !!cookies.get('token');
    if (isAuthenticated) {
      window.location.href = '/';
    }
  }, []);
  const getProfileAndSet = async () => {
    const pharmacyProfile = getPharmacyProfile();
    const pharmacy = JSON.stringify(pharmacyProfile);
    await savePharmacyToLocal(pharmacy);
  };
  const login = async () => {
    await loginPharmacy({ email: email, password: password }).then(response => {
      cookies.set('token', response.token, { path: '/' });
      if (response.token) {
        getProfileAndSet().then(() => {
          window.location.href = '/';
        });
      }
    });
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <img src={headerLogo} alt="GoPuls" style={{ height: '5rem', objectFit: 'contain' }} />
        <p className="error"> {error} </p>
        <input type="text" onChange={e => setEmail(e.target.value)} placeholder="Email" />
        <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" />
        <button onClick={login}>Login</button>
      </div>
    </div>
  );
}

export default LoginPage;
