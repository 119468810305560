import { add } from 'date-fns';

import axios from 'axios';

export const reverseGeocode = async (latitude, longitude) => {
  if (!latitude || !longitude) {
    return null;
  }
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

const BERLIN_REGION = {
  latitude: 52.49081635226,
  longitude: 13.405977040529251,
};

export const getRegionFromDetails = placeDetails => {
  let latitude = placeDetails?.geometry?.location?.lat;
  let longitude = placeDetails?.geometry?.location?.lng;
  if (typeof latitude === 'function') {
    latitude = latitude();
    longitude = longitude();
  }
  if (!latitude || !longitude) {
    return BERLIN_REGION;
  }
  return {
    latitude,
    longitude,
  };
};

const getAddressComponent = (placeDetails, type) => {
  return (
    placeDetails?.address_components?.find(component => component.types?.[0] === type)?.long_name ||
    ''
  );
};

export const getPostalCode = async placeDetails => {
  const region = getRegionFromDetails(placeDetails);
  let postalCode = getAddressComponent(placeDetails, 'postal_code');
  if (postalCode) {
    return postalCode;
  }
  const res = await reverseGeocode(region.latitude, region.longitude);
  res?.results[0].address_components.forEach(item => {
    switch (item.types[0]) {
      case 'postal_code':
        postalCode = item.long_name;
    }
  });
  return postalCode;
};

export const getBuildingNumber = placeDetails => {
  return getAddressComponent(placeDetails, 'street_number');
};

export const getCity = placeDetails => {
  return (
    getAddressComponent(placeDetails, 'locality') ||
    getAddressComponent(placeDetails, 'administrative_area_level_3') ||
    getAddressComponent(placeDetails, 'administrative_area_level_2') ||
    getAddressComponent(placeDetails, 'administrative_area_level_1')
  );
};

export const getCountry = placeDetails => {
  return getAddressComponent(placeDetails, 'country');
};

export const getProvince = placeDetails => {
  return getAddressComponent(placeDetails, 'administrative_area_level_3');
};

export const getStreetName = placeDetails => {
  return getAddressComponent(placeDetails, 'route');
};

export const getCoordinates = placeDetails => {
  return placeDetails?.geometry?.location;
};

export const googlePlaceDetailsToAddress = async address => {
  const region = getRegionFromDetails(address);
  const postalCode = await getPostalCode(address);
  return {
    postalCode: postalCode,
    formattedAddress: `${getBuildingNumber(address) || ''} ${getStreetName(address) || ''} ${
      postalCode || ' '
    } ${getCity(address) || ''}, ${getCountry(address) || ''}`,
    streetNumber: getBuildingNumber(address),
    street: getStreetName(address),
    province: getProvince(address),
    country: getCountry(address),
    city: getCity(address),
    latitude: region.latitude,
    longitude: region.longitude,
  };
};

export const getAddressFromGooglePlaceResult = async address => {
  const region = getRegionFromDetails(address);
  const postalCode = await getPostalCode(address);
  return {
    postalCode: postalCode,
    address1: getBuildingNumber(address),
    address2: getStreetName(address),
    province: getProvince(address),
    country: getCountry(address),
    city: getCity(address),
    latitude: region.latitude,
    longitude: region.longitude,
  };
};

export const getFormattedAddressFromAddressObject = function (address, showCountry = false) {
  if (!address) {
    return '';
  }
  return ` ${address.address2} ${address.address1}, ${address.postalCode || ' '} ${
    address.city || ''
  }${showCountry ? ', ' + address.country : ''}`;
};

export const getShortFormattedAddress = function (address) {
  if (!address) {
    return '';
  }
  return ` ${address.address2} ${address.address1}`;
};
