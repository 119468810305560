import React, { useEffect, useState, useCallback } from 'react';
import SideBar from '../../components/common/sidebar/SideBar';
import MainSection from '../../components/MainSection';
import { Button, Col, Row, Select, Input, Tooltip, Typography, DatePicker } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import './index.css';
import Column from 'antd/lib/table/Column';
import { getBotendienstOrders, getBotendienstOrdersExport } from '../../services/botendienst';
import { Redirect } from 'react-router-dom';
import { ReactComponent as Cactus } from '../../images/cactus.svg';
import { ReactComponent as ResetFilters } from '../../images/resetFilters.svg';
import 'dayjs/locale/de';
import OrderLabel from '../orders/OrderLabel';

import BotendienstModal from '../../components/common/botendienstModal/BotendienstModal';

import { Table } from 'antd/lib';
import localeDE from 'antd/es/date-picker/locale/de_DE';
import { getPharmacyOperationTime } from '../../services/pharmacy';

const _ = require('lodash');
const formatOrdering = orderShort => {
  if (orderShort === 'asc') {
    return 'ascend';
  } else if (orderShort === 'desc') {
    return 'descend';
  }
};
export const formatDate = (date, dateFormat = 'dd.MM.yyyy, HH:mm') => {
  if (date) {
    return format(new Date(date), dateFormat);
  }
  return '';
};

const pageSizeOptions = ['10', '20', '30'];

const NoMessangerOrders = () => {
  return (
    <div className="no-messanged-orders">
      <div className="no-messanged-orders-title">
        <Cactus />
        <Typography style={{ fontSize: '28px' }}>
          Sie haben noch keine Botenaufträge aufgegeben
        </Typography>
        <Typography style={{ fontSize: '16px' }}>
          So können Sie einen GoPuls Boten beauftragen:
        </Typography>
        <div className="no-messanged-step-wrapper">
          <div className="no-messanged-step">
            <div className="horizontal-line-step" />
            <Typography className="number-step">01</Typography>

            <Typography className="text-step">Klicken Sie auf “Boten beauftragen”</Typography>
          </div>
          <div className="no-messanged-step">
            <div className="horizontal-line-step" />
            <Typography className="number-step">02</Typography>

            <Typography className="text-step">
              Geben Sie Lieferadresse und Kontaktdaten ein
            </Typography>
          </div>
          <div className="no-messanged-step">
            <div className="horizontal-line-step" />
            <Typography className="number-step">03</Typography>

            <Typography className="text-step">Wählen Sie die gewünschte Abholzeit aus</Typography>
          </div>
          <div className="no-messanged-step">
            <div className="horizontal-line-step" />
            <Typography className="number-step">04</Typography>

            <Typography className="text-step">
              Erledigt! GoPuls wird die Bestellung abholen
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoMessangerOrdersFiltered = () => {
  return (
    <div className="no-messanged-orders">
      <div className="no-messanged-orders-title">
        <Cactus />
        <Typography style={{ fontSize: '28px' }}>Keine Ergebnisse gefunden</Typography>
        <Typography style={{ fontSize: '16px', maxWidth: '370px', margin: 'auto' }}>
          Versuchen Sie einen anderen Filter zu nutzen, prüfen Sie Ihre gesetzten Filter oder die
          Schreibweise der Suchbegriffe
        </Typography>
      </div>
    </div>
  );
};
const { Title } = Typography;

const BotendienstPage = () => {
  const [botendienstStatus, setBotendienstStatus] = useState(true);
  const [filters, setFilters] = useState([]);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 10,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [isActiveResetFilters, setIsActiveResetFilters] = useState(false);
  const [isShouldMakeRequest, setIsShouldMakeRequest] = useState(false);
  const [sortField, setSortField] = useState('delivery_start_time');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchFilter, setSearchFilter] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [operationalTime, setOperationalTime] = useState({});

  const LoadInitialData = async () => {
    setLoadingData(true);
    const pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
    if (pharmacy) {
      setBotendienstStatus(pharmacy.botendienstStatus);
      loadOperationalTime(pharmacy.identifier);
    }
  };
  const loadOperationalTime = async identifier => {
    const operationalTime = await getPharmacyOperationTime(identifier);
    setOperationalTime(operationalTime);
  };

  useEffect(() => {
    LoadInitialData();
  }, []);

  const handleSearchClick = async event => {
    event.preventDefault();
    // await dispatch(fetchShipments({ page, searchQuery, searchFilter }));
  };
  const codes = [];
  const getOrders = async () => {
    setLoadingData(true);
    const payload = {
      page: pagination.currentPage,
      limit: pagination.limit,
      ...searchFilter,
      sortBy: sortOrder && sortField ? sortField : '',
      sortDir: sortOrder,
    };
    if (searchFilter.phone) {
      payload['phone'] = encodeURI(searchFilter.phone);
    }
    if (searchFilter.delivery_start_time) {
      const formattedStartTime = searchFilter.delivery_start_time.format('YYYY-MM-DD');
      payload['delivery_start_time'] = formattedStartTime;
    }
    if (searchFilter.created_at) {
      const formattedCreatedDate = searchFilter.created_at.format('YYYY-MM-DD');
      payload['created_at'] = formattedCreatedDate;
    }
    if (searchFilter.order_id_identifier) {
      payload['order_id_identifier'] = searchFilter.order_id_identifier.trim();
    }
    if (searchFilter.name) {
      payload['name'] = searchFilter.name.trim();
    }
    if (searchFilter.address) {
      payload['address'] = searchFilter.address.trim();
    }

    await getBotendienstOrders(payload).then(data => {
      setPagination(data.pagination);
      setOrders(data.orders);
      setLoadingData(false);
      setFilters(data.filters);
    });
    setIsShouldMakeRequest(false);
  };
  useEffect(() => {
    getOrders();
  }, [pagination.currentPage, pagination.limit, sortField, sortOrder]);

  useEffect(() => {
    if (!_.isEmpty(searchFilter) || sortField !== 'delivery_start_time' || sortOrder !== 'desc') {
      setIsActiveResetFilters(true);
    } else {
      setIsActiveResetFilters(false);
    }
  }, [searchFilter, sortField, sortOrder]);

  const handleSearchFilter = useCallback(
    searchArgs => {
      let sfilter = {};
      if (searchFilter) {
        sfilter = searchFilter;
      } else {
        sfilter = {};
      }
      setSearchFilter({ ...sfilter, ...searchArgs });
      setPagination(pagination => ({ ...pagination, currentPage: 1 }));
    },
    [searchFilter],
  );
  function extractFileName(inputString) {
    const match = inputString.match(/filename=([^;]+)/);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  const handleExportTable = async () => {
    const payload = {
      page: pagination.currentPage,
      limit: pagination.limit,
      exportData: true,
      ...searchFilter,
      sortBy: sortOrder && sortField ? sortField : '',
      sortDir: sortOrder,
    };
    await getBotendienstOrdersExport(payload).then(({ data, fileName }) => {
      const href = URL.createObjectURL(data);
      const preparedFileName = extractFileName(fileName);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', preparedFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
    // window.open('/api/v1/orders/rx?page=1&exportData=true');
  };

  const clearCurrentFilter = useCallback(
    filter => {
      let sfilter = {};
      if (searchFilter) {
        sfilter = { ...searchFilter };
        if (filter === 'recipientPhone') {
          delete sfilter['recipientPhone'];
          delete sfilter['recipientCountryCode'];
        }
        delete sfilter[filter];
      }
      setSearchFilter({ ...sfilter });
      setIsShouldMakeRequest(true);
    },
    [searchFilter],
  );
  useEffect(() => {
    if (isShouldMakeRequest) {
      getOrders();
    }
  }, [searchFilter, isShouldMakeRequest]);
  const statuses = [
    { value: 'pending', label: 'Eingegangen' },
    { value: 'order_picked', label: 'Abgeholt' },
    { value: 'in_transit', label: 'In Zustellung' },
    { value: 'delivered', label: 'Zugestellt' },
    { value: 'closed', label: 'Storniert' },
    { value: 'delivery_failed', label: 'Zustellung fehlgeschlagen' },
  ];

  function handleDetails(e) {
    window.location.href = `/orders/history/${e.target.value}`;
  }
  const hideModal = () => {
    setModalVisible(false);
    const pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
    if (pharmacy) {
      loadOperationalTime(pharmacy.identifier);
    }
  };
  if (!botendienstStatus) {
    return <Redirect to="/" />;
  }
  const handleResetFilters = () => {
    setSearchFilter(() => ({}));
    setSortField('delivery_start_time');
    setSortOrder('desc');
    setIsShouldMakeRequest(true);
  };
  let locale = {
    emptyText: <NoMessangerOrdersFiltered />,
    triggerDesc: 'Klicken Sie, um die Liste absteigend zu sortieren',
    triggerAsc: 'Klicken Sie, um die Liste aufsteigend zu sortieren',
    cancelSort: 'Klicken Sie, um Sortierung aufzuheben',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <SideBar />
      <MainSection>
        <div className="botendienst-page-header">
          <div>
            <Title style={{ textAlign: 'left', fontSize: '40px', marginBottom: '16px' }}>
              Botendienst
            </Title>
            <Typography
              level={3}
              style={{ textAlign: 'left', fontSize: '16px', maxWidth: '450px', fontWeight: 400 }}
            >
              Hier können Sie einen GoPuls Boten beauftragen, um Ihre Kundenbestellungen abholen und
              ausliefern zu lassen.
            </Typography>
          </div>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setModalVisible(true);
            }}
            style={{ background: '#5B71EF', padding: '12px 20px', height: 'auto' }}
          >
            Boten beauftragen
          </Button>
        </div>
        <div>
          <div className="table-header-reset-filter">
            <Button
              className="secondary-button"
              onClick={() => handleResetFilters()}
              disabled={!isActiveResetFilters}
            >
              <ResetFilters />
              Alle Filter zurücksetzen
            </Button>
            <Button className="secondary-button" onClick={() => handleExportTable()}>
              <DownloadOutlined />
              Liste exportieren
            </Button>
          </div>
          {!orders.length && _.isEmpty(filters) ? (
            <NoMessangerOrders />
          ) : (
            <Table
              dataSource={orders}
              locale={locale}
              loading={loadingData}
              onChange={(pagination, _filters, sorter) => {
                if (sorter.order) {
                  if (sorter.order === 'ascend') {
                    setSortOrder('asc');
                  } else if (sorter.order === 'descend') {
                    setSortOrder('desc');
                  }
                } else {
                  setSortOrder('');
                }
                if (sorter.field) {
                  setSortField(sorter.field);
                }
              }}
              pagination={{
                defaultCurrent: pagination.currentPage,
                current: pagination.currentPage,
                total: pagination.totalRecords,
                pageSizeOptions: pageSizeOptions,
                defaultPageSize: pagination.limit,
                onChange: (page, limit) => {
                  setPagination(pagination => ({ ...pagination, currentPage: page, limit }));
                },
              }}
            >
              <Column
                title="Id"
                key="order_id_identifier"
                sorter
                dataIndex="order_id_identifier"
                filteredValue={searchFilter.order_id_identifier || null}
                sortOrder={sortField === 'order_id_identifier' ? formatOrdering(sortOrder) : null}
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Input
                          value={searchFilter.order_id_identifier || ''}
                          style={{ marginBottom: '8px' }}
                          placeholder="ID"
                          onChange={event =>
                            handleSearchFilter({ order_id_identifier: event.target.value })
                          }
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.order_id_identifier ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('order_id_identifier')}>
                          Zurücksetzen
                        </Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.id ? 'rgb(24, 144, 255)' : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(shipmentId, shipment) => {
                  return <span>{shipmentId}</span>;
                }}
              />
              <Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter
                filteredValue={searchFilter.status || null}
                sortOrder={sortField === 'status' ? formatOrdering(sortOrder) : null}
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Select
                          value={searchFilter.status || 'Select'}
                          defaultValue={'Select'}
                          style={{ width: '150px' }}
                          options={statuses}
                          onChange={event => handleSearchFilter({ status: event })}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.status ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('status')}>Zurücksetzen</Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.shipmentStatus
                          ? 'rgb(24, 144, 255)'
                          : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(statuses, shipment) => {
                  switch (statuses) {
                    case 'pending':
                      return <div className="status-badge pending">Eingegangen</div>;
                    case 'order_picked':
                      return <div className="status-badge in-transit">Abgeholt</div>;
                    case 'in_transit':
                      return <div className="status-badge in-transit"> In Zustellung</div>;
                    case 'delivered':
                      return <div className="status-badge delivered">Zugestellt</div>;
                    case 'closed':
                      return <div className="status-badge closed"> Storniert</div>;
                    case 'delivery_failed':
                      return (
                        <div className="status-badge delivery_failed">
                          {' '}
                          Zustellung fehlgeschlagen
                        </div>
                      );
                    default:
                      break;
                  }
                }}
              />
              <Column
                title="Etikett"
                key="Etikett"
                dataIndex="Etikett"
                render={(shipmentId, shipment) => {
                  return <OrderLabel order={shipment} />;
                }}
              />
              <Column
                title="Kunde"
                key="id"
                dataIndex="name"
                sorter
                filteredValue={searchFilter.name || null}
                sortOrder={sortField === 'name' ? formatOrdering(sortOrder) : null}
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Input
                          value={searchFilter.name || ''}
                          style={{ marginBottom: '8px' }}
                          placeholder="Name des Kunden"
                          onChange={event => handleSearchFilter({ name: event.target.value })}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.name ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('name')}>Zurücksetzen</Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.trackingId
                          ? 'rgb(24, 144, 255)'
                          : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(user_details, shipment) => {
                  return <>{shipment.user_details?.name}</>;
                }}
              />
              <Column
                title="Lieferadresse"
                key="lieferadresse"
                dataIndex="address"
                filteredValue={searchFilter.address || null}
                sortOrder={sortField === 'address' ? formatOrdering(sortOrder) : null}
                sorter
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Input
                          value={searchFilter.address || ''}
                          style={{ marginBottom: '8px' }}
                          placeholder="Lieferadresse"
                          onChange={event => handleSearchFilter({ address: event.target.value })}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.address ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('address')}>Zurücksetzen</Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.lieferadresse
                          ? 'rgb(24, 144, 255)'
                          : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(user_details, shipment) => {
                  return <>{shipment.user_details?.address}</>;
                }}
              />
              <Column
                title="Telefonnummer"
                key="telefonnummer"
                dataIndex="phone"
                filteredValue={searchFilter.phone || null}
                sortOrder={sortField === 'phone' ? formatOrdering(sortOrder) : null}
                sorter
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Row>
                          <Col>
                            <Tooltip
                              title={`Geben Sie Telefonnummer ohne vorangestellte “0” oder “+”`}
                            >
                              <Input
                                value={searchFilter?.phone || ''}
                                style={{ marginBottom: '8px' }}
                                placeholder="Telefonnummer eingeben"
                                onChange={event =>
                                  handleSearchFilter({
                                    phone: event.target.value.trim().replace(/^0+/, ''),
                                  })
                                }
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                        <Button
                          htmlType="submit"
                          type="primary"
                          disabled={searchFilter.phone ? false : true}
                          onClick={() => getOrders()}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('phone')}>Zurücksetzen</Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color:
                          searchFilter?.recipientPhone || searchFilter?.recipientCountryCode
                            ? 'rgb(24, 144, 255)'
                            : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(user_details, shipment) => {
                  return <>{shipment.user_details?.phone}</>;
                }}
              />
              <Column
                width={'5%'}
                title="Anzahl der Medikamente"
                key="Anzahl der Medikamente"
                dataIndex="Anzahl der Medikamente"
                render={(shipmentId, shipment) => {
                  return <>{shipment.number_of_medications}</>;
                }}
              />
              <Column
                title="Bestelldatum"
                key="bestelldatum"
                dataIndex="created_at"
                sorter
                filteredValue={searchFilter.created_at || null}
                sortOrder={sortField === 'created_at' ? formatOrdering(sortOrder) : null}
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={searchFilter.created_at || ''}
                          style={{ marginBottom: '8px', width: '100%' }}
                          onChange={date => handleSearchFilter({ created_at: date })}
                          locale={localeDE}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.created_at ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('created_at')}>
                          Zurücksetzen
                        </Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.created_at
                          ? 'rgb(24, 144, 255)'
                          : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(_, shipment) => (
                  <>
                    {formatDate(shipment.created_at, 'dd.MM.yyyy')}{' '}
                    {formatDate(shipment.created_at, 'HH:mm')}
                  </>
                )}
              />
              <Column
                title="Abholzeit"
                key="abholzeit"
                dataIndex="delivery_start_time"
                sorter
                defaultSortOrder={'descend'}
                filteredValue={searchFilter.delivery_start_time || null}
                sortOrder={sortField === 'delivery_start_time' ? formatOrdering(sortOrder) : null}
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={searchFilter.delivery_start_time || ''}
                          style={{ marginBottom: '8px', width: '100%' }}
                          onChange={date => {
                            handleSearchFilter({ delivery_start_time: date });
                          }}
                          locale={localeDE}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.delivery_start_time ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('delivery_start_time')}>
                          Zurücksetzen
                        </Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.abholzeit ? 'rgb(24, 144, 255)' : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={(_, shipment) => {
                  return (
                    <>
                      {formatDate(shipment.order_start_time, 'dd.MM.yyyy')}{' '}
                      {formatDate(shipment.order_start_time, 'HH:mm')}
                    </>
                  );
                }}
              />
              <Column
                title="Notizen"
                key="info"
                dataIndex="info"
                filteredValue={searchFilter.info || null}
                sortOrder={sortField === 'info' ? formatOrdering(sortOrder) : null}
                sorter
                filterDropdown={
                  <Row style={{ padding: '8px' }}>
                    <Col>
                      <form onSubmit={handleSearchClick}>
                        <Input
                          value={searchFilter.info || ''}
                          style={{ marginBottom: '8px' }}
                          placeholder="Stichwörter"
                          onChange={event => handleSearchFilter({ info: event.target.value })}
                        />
                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => getOrders()}
                          disabled={searchFilter.info ? false : true}
                        >
                          Suchen
                        </Button>
                        <Button onClick={() => clearCurrentFilter('info')}>Zurücksetzen</Button>
                      </form>
                    </Col>
                  </Row>
                }
                filterIcon={() => {
                  return (
                    <SearchOutlined
                      style={{
                        color: searchFilter?.note ? 'rgb(24, 144, 255)' : 'rgb(0, 0, 0, 0.29)',
                      }}
                    />
                  );
                }}
                render={notes => <>{notes}</>}
              />
            </Table>
          )}
        </div>
      </MainSection>
      {modalVisible && (
        <BotendienstModal
          visible={modalVisible}
          operationalTime={operationalTime}
          onClose={hideModal}
          onOrderCreated={() => getOrders()}
        />
      )}
    </div>
  );
};

export default BotendienstPage;
