import React from 'react';
import PropTypes from 'prop-types';

const MainHeader = ({ headerTitle }) => {
  return (
    <div
      style={{
        height: '13%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderBottom: '1px solid #000000',
        padding: 0,
      }}
    >
      <p
        className="header-title"
        style={{ color: '#000', fontSize: '1.5rem', paddingBottom: '0px!' }}
      >
        {headerTitle}
      </p>
    </div>
  );
};

MainHeader.propTypes = {
  headerTitle: PropTypes.string,
};

export default MainHeader;
