import React from 'react';

const ContactDetails = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(0, 163, 255, 0.11)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '87%',
        padding: '0 2rem',
      }}
    >
      <div>
        <div>
          <div className="details-title">
            <p>
              Du hast Probleme?
              <br />
              Unser Service Team ist jeder Zeit für Dich
              <br />
              da!
            </p>
          </div>
          <div className="contact-details">
            <p>Telefon: 030 837 998 69</p>
            <p>E-Mail: support@gopuls.de</p>
          </div>
        </div>
      </div>
      <div>
        <p className="address">
          GoPuls<br />
          Aurora Gesundheit GmbH
          <br />
          Knaackstraße 64
          <br />
          10435 Berlin
          <br />
          Deutschland
        </p>
      </div>
    </div>
  );
};

export default ContactDetails;
