import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { ReloadOutlined, HomeOutlined, PhoneOutlined, LogoutOutlined } from '@ant-design/icons';
import { ReactComponent as Bike } from '../../../images/bike.svg';
import { getPharmacyProfile } from '../../../services/pharmacy';

import Cookies from 'universal-cookie';
import { loginScreenLogo } from '../../../utils/images';
import { savePharmacyToLocal } from '../../../utils/pharmacy';
const _ = require('lodash');
const { Text } = Typography;
const cookies = new Cookies();

function LogOut() {
  cookies.remove('token', { path: '/' });
  localStorage.removeItem('pharmacy');
  window.location.reload();
}

const SideBar = () => {
  const [pharmacy, setPharmacy] = useState(localStorage.getItem('pharmacy'));
  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('pharmacy');
      const parcesPharmacyFromLocalStorage = JSON.parse(item);
      if (!_.isEmpty(parcesPharmacyFromLocalStorage)) {
        setPharmacy(parcesPharmacyFromLocalStorage);
      } else {
        getPharmacyProfileFunction();
      }
    }

    window.addEventListener('pharmacy', checkUserData);
    checkUserData();
    return () => {
      window.removeEventListener('pharmacy', checkUserData);
    };
  }, []);

  const getPharmacyProfileFunction = async () => {
    await getPharmacyProfile().then(response => {
      const pharmacy = JSON.stringify(response);
      setPharmacy(response);

      savePharmacyToLocal(pharmacy);
    });
  };

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={loginScreenLogo} alt="GoPuls" />
      </div>
      <div className="pages">
        <Link className={window.location.pathname === '/' ? 'link active' : 'link'} to={'/'}>
          <HomeOutlined style={{ fontSize: '24px' }} />
          <Text style={{ fontSize: '16px' }}>Start</Text>
        </Link>
        <Link
          className={window.location.pathname === '/orders/history' ? 'link active' : 'link'}
          to={'/orders/history'}
        >
          <ReloadOutlined style={{ fontSize: '24px' }} />
          <Text style={{ fontSize: '16px' }}>Alte Bestellungen</Text>
        </Link>
        {pharmacy?.botendienstStatus && (
          <Link
            className={window.location.pathname === '/botendienst' ? 'link active' : 'link'}
            to={'/botendienst'}
          >
            <Bike style={{ fontSize: '24px' }} />
            <Text style={{ fontSize: '16px' }}>Botendienst</Text>
          </Link>
        )}
      </div>
      <div>
        <Link
          className={window.location.pathname === '/contact' ? 'link active' : 'link'}
          to={'/contact'}
        >
          <PhoneOutlined style={{ fontSize: '24px' }} />
          <Text style={{ fontSize: '16px' }}>Notfallkontakte</Text>
        </Link>

        <Link onClick={LogOut} className={'link'}>
          <LogoutOutlined style={{ fontSize: '24px' }} />
          <Text style={{ fontSize: '16px' }}>Ausloggen</Text>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
