import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOrders, getOrderLabel } from '../../services/orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../../components/common/sidebar/SideBar';
import NoOrders from '../../components/NoOrders';
import MainSection from '../../components/MainSection';
import useSound from 'use-sound';
import boopSfx from '../../media/notification.mp3';
import { format } from 'date-fns';
import { getDeliveryPartnerUrl, handleOrderLabelDownload } from '../../helper/order';
import OrderLabel from './OrderLabel';

//const audio = new Audio('./notification.wav');

function calculateTime(order_start_time) {
  let diff = new Date().getTime() - new Date(order_start_time).getTime();
  return (diff / 60000).toFixed(0);
}

function OrdersPage() {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [play] = useSound(boopSfx);
  const notify = () => toast('New Order Received!');

  async function fetchOrders(flag) {
    let currentOrders = await getOrders();
    if (flag === 1 && orders.length !== currentOrders.length) {
      play();
      notify();
      setOrders(currentOrders);
    } else if (flag === 0) {
      setOrders(currentOrders);
    }
  }

  useEffect(() => {
    fetchOrders(0);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      fetchOrders(1);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  function handleDetails(e) {
    history.push(`/orders/${e.target.value}`);
  }

  function renderOrder() {
    return orders.length === 0 ? (
      <NoOrders />
    ) : (
      <div>
        <div className="heading">
          <h2>Start</h2>
        </div>
        <table style={{ width: '80vw' }}>
          <tr className="orders-header">
            <th>Order ID</th>
            <th>Lieferpartner</th>
            <th>Vergangene Zeit</th>
            <th>Lieferzeitfenster</th>
            <th>Produkte</th>
            <th>Etikett</th>
          </tr>
          <div className="orders-table">
            {orders.map((order, idx) => {
              let {
                _id,
                order_id,
                order_id_identifier,
                order_start_time,
                line_items,
                order_end_time,
              } = order;
              if (!order_id_identifier) {
                order_id_identifier = order_id;
              }
              return (
                <div className="item" key={_id}>
                  <tr className="item-details">
                    <td>
                      <div>
                        <p>#{order_id_identifier}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <img
                          className="delivery-partner"
                          src={getDeliveryPartnerUrl(order.delivery_partner)}
                          alt="delivery_partner"
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{calculateTime(order_start_time)} Minuten</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          <span>
                            {format(new Date(order_start_time), 'HH:mm')} -{' '}
                            {format(new Date(order_end_time), 'HH:mm')}
                          </span>
                          <br />
                          <span>{format(new Date(order_start_time), 'dd.MM.yyyy')}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{line_items.length}</p>
                      </div>
                    </td>
                    <td>
                      <p>
                        <OrderLabel order={order} style={{ fontSize: 20 }} />
                      </p>
                    </td>
                  </tr>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      value={_id}
                      onClick={handleDetails}
                      style={{
                        backgroundColor: 'red',
                        padding: '0.5rem',
                        border: 0,
                        color: '#ffff',
                        width: '200px',
                        borderRadius: '5px',
                        marginTop: '0.5rem',
                        fontWeight: 700,
                      }}
                    >
                      Aufgabe starten!
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </table>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <SideBar />
      <ToastContainer />
      <MainSection>{renderOrder()}</MainSection>
    </div>
  );
}

export default OrdersPage;
