import React, { useState, useEffect } from "react";
import { getPharmacyProducts, getPharmacyProfile } from "../../services/pharmacy";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const SubstituteItem = ({ index, addSubstitute, item, disabledCheck }) => {
  const [pharmacyIdentifier, setPharmacyIdentifier] = useState("");

  /**
   * @author Shubham Dubey
   * @description format product into value and label
   * @param products products 
   * @return product[] 
   */
  function formatProducts(products) {
    let formattedProducts = [];
    formattedProducts = products.map((product) => {
      return {
        value: product,
        label: product?.name || "",
      };
    });
    return formattedProducts;
  }

  /**
   * @author Shubham Dubey
   * @description fetching products
   * @param inputValue query 
   * @return product[] 
   */
  const getProducts = async (inputValue) => {
    let _products = await getPharmacyProducts({ query: inputValue, pharmacyIdentifier });
    _products = formatProducts(_products);
    return _products;
  };

  const customStyles = {
    control: (base, state) => ({
      // none of react-select's styles are passed to <Control />
      ...base,
      outline: "none",
      border: state.isFocused ? "2px solid #000" : "2px solid rgb(232, 232, 232)",
      boxShadow: state.isFocused ? "2px solid #000" : "2px solid rgb(232, 232, 232)",
      "&:hover": {
        border: state.isFocused ? "2px solid #000" : "2px solid rgb(232, 232, 232)",
      },
    }),
  };

  /**
   * @author Shubham Dubey
   * @description Drop Down
   * @param props
   */
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span class="material-icons">search</span>
      </components.DropdownIndicator>
    );
  };

  const fetchPharmacy = async () => {
    const pharmacy = await getPharmacyProfile();
    setPharmacyIdentifier(pharmacy.identifier);
  }

  useEffect(() => {
    fetchPharmacy();
  }, []);

  return (
    <tr className="table-row light-gray">
      {/* --- */}
      <td className="table-item no-border">
        <div className="table-in">
          <div className="substitute-input-container">
            <input
              value={item?.substitute?.quantity || ""}
              onChange={(e) => {
                let sub = {
                  ...item.substitute,
                  quantity: parseInt(e.target.value || 0),
                };
                addSubstitute(index, sub);
              }}
              className={
                item?.substitute?.quantity === ""
                  ? "substitute-input substitute-input-error-border"
                  : "substitute-input substitute-input-default-border"
              }
              placeholder="Anzahl"
              disabled={disabledCheck()}
              type="number"
              required
              min={0}
            />
          </div>
        </div>
      </td>
      {/* --- */}
      <td className="table-item no-border">
        <div className="table-in">
          <div className="substitute-input-container">
            <AsyncSelect
              styles={customStyles}
              className="substitute-input"
              placeholder="Produkt Name"
              value={item?.substitute?.name && { label: item.substitute.name }}
              cacheOptions
              loadOptions={getProducts}
              onChange={(newValue, actionMeta) => {
                const { value } = newValue;
                let sub = {
                  ...item.substitute,
                  pzn: value.pzn,
                  packageSize: value.packSize,
                  id: value.id,
                  name: value.name,
                  price: value.price
                };
                addSubstitute(index, sub);
              }}
              isDisabled={disabledCheck()}
              components={{ DropdownIndicator }}
            />
          </div>
        </div>
      </td>
      {/* --- */}
      <td className="table-item no-border">
        <div className="table-in">
          <div className="substitute-input-container">
            <AsyncSelect
              styles={customStyles}
              className="substitute-input"
              value={item?.substitute?.pzn && { label: item.substitute.pzn }}
              placeholder="PZN"
              cacheOptions
              loadOptions={getProducts}
              onChange={(newValue, actionMeta) => {
                const { value } = newValue;
                let sub = {
                  ...item.substitute,
                  pzn: value.pzn,
                  packageSize: value.packSize,
                  id: value.id,
                  name: value.name,
                  price: value.price
                };
                addSubstitute(index, sub);
              }}
              isDisabled={disabledCheck()}
              components={{ DropdownIndicator }}
            />
          </div>
        </div>
      </td>
      {/* --- */}
      <td className="table-item no-border">
        <div className="table-in">
          <div className="substitute-input-container">
            <input
              value={item?.substitute?.packageSize ?? ""}
              onChange={(e) => {
                let sub = {
                  ...item.substitute,
                  packageSize: e.target.value,
                };
                addSubstitute(index, sub);
              }}
              placeholder="Packung"
              className={
                item?.substitute?.packageSize === ""
                  ? "substitute-input substitute-input-error-border"
                  : "substitute-input substitute-input-default-border"
              }
              disabled={disabledCheck()}
              type="text"
              required
            />
          </div>
        </div>
      </td>
      <td className="table-item no-border">
        <div className="table-in"></div>
      </td>
    </tr>
  );
};

export default SubstituteItem;
