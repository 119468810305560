import React from 'react';

function MainSection({ children }) {
  return (
    <div
      style={{
        flex: 1,
        width: '80vw',
        overflow: 'scroll',
        padding: '24px',
        height: '100vh',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </div>
  );
}

export default MainSection;
