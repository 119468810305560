import React from 'react';
import '../css/no-orders.css';
import NoOrdersSvg from '../images/no-orders.svg';
const NoOrders = () => {
  return (
    <div id="no-orders">
      <img src={NoOrdersSvg} alt="No Orders" id="no-order-icon" />
      <p id="no-orders-title">
        Alles erledigt! Keine weiteren
        <br />
        Aufgaben warten auf Dich!
      </p>
    </div>
  );
};

export default NoOrders;
