import React, { useEffect, useState } from 'react';
import { getOrdersHistory } from '../../services/orders';
import SideBar from '../../components/common/sidebar/SideBar';
import NoOrders from '../../components/NoOrders';
import MainSection from '../../components/MainSection';
import { getDeliveryPartnerUrl } from '../../helper/order';
import OrderLabel from './OrderLabel';

function calculateTime(completed_at) {
  let diff = new Date().getTime() - new Date(completed_at).getTime();
  return (diff / 60000).toFixed(0);
}

function OrdersHistoryPage() {
  const [orders, setOrders] = useState([]);

  useEffect(async () => {
    let orders = await getOrdersHistory();
    setOrders(prevState => orders);
  }, []);

  function handleDetails(e) {
    window.location.href = `/orders/history/${e.target.value}`;
  }

  function renderHistoryOrders() {
    return orders.length === 0 ? (
      <NoOrders />
    ) : (
      <div className="orders-page">
        <div className="heading">
          <h2>Alte Bestellungen</h2>
        </div>
        <table style={{ width: '80vw' }}>
          <tr className="orders-header">
            <th>Order ID</th>
            <th>Lieferpartner</th>
            <th>Packzeit</th>
            <th>Produkte</th>
            <th>Etikett</th>
          </tr>

          <div className="orders-table">
            {orders.map((order, idx) => {
              let { _id, order_id, order_id_identifier, completed_at, line_items, status } = order;
              if (!order_id_identifier) {
                order_id_identifier = order_id;
              }
              return (
                <div className="item" key={_id}>
                  <tr className="item-details">
                    <td>
                      <div>
                        <p>#{order_id_identifier}</p>
                        <button
                          className={
                            order.status === 'fulfilled'
                              ? 'task-completed-button'
                              : 'task-cancelled-button'
                          }
                          style={{ width: '120px' }}
                        >
                          {order.status === 'fulfilled' ? 'Abgeschlossen' : 'Storniert'}
                        </button>
                      </div>
                    </td>
                    <td>
                      <div>
                        <img
                          className="delivery-partner"
                          src={getDeliveryPartnerUrl(order.delivery_partner)}
                          alt="delivery_partner"
                        />
                      </div>
                    </td>
                    <td style={{ width: '45%' }}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <p>{calculateTime(completed_at)} Minuten</p>
                        <button className="details-button" value={_id} onClick={handleDetails}>
                          Details
                        </button>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{line_items.length}</p>
                      </div>
                    </td>
                    <td>
                      <p>
                        <OrderLabel order={order} style={{ fontSize: 20 }} />
                      </p>
                    </td>
                  </tr>
                </div>
              );
            })}
          </div>
        </table>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <SideBar />
      <MainSection>{renderHistoryOrders()}</MainSection>
    </div>
  );
}

export default OrdersHistoryPage;
