import React from 'react';
import OrdersPage from '../orders/OrdersPage';

function HomePage() {
  return (
    <div>
      <OrdersPage />
    </div>
  );
}

export default HomePage;
