import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

const InteractiveItem = ({ item, needsChevron, style, isFocused }) => {
  const { icon, label, subline } = item || {};

  return (
    <div tabIndex={-1} className={classNames(style, 'w-full')}>
      <div
        className={classNames(
          'py-2 flex items-center px-sm flex-row rounded-lg justify-between hover:bg-neutral/4 w-full flex-shrink-0',
          {
            'bg-neutral/4': isFocused,
          },
        )}
      >
        <div className={`flex flex-row items-center`}>
          <div className={`ml-sm`}>
            {label ? (
              <Typography className={'text-left text-lg text-blue-200'}>{label}</Typography>
            ) : null}

            {item.subline ? (
              <Typography className={`text-sm text-left text-blue-200/60`}>{subline}</Typography>
            ) : null}
          </div>
        </div>

        {/* Chevron */}
        {needsChevron ? (
          // Adding the margin-left to a wrapper View because we can only add a single style definition to the Icon component
          <div className={`ml-xs opacity-40 justify-end`}>
            icon right
            {/* <Icon icon="ChevronRight" color="neutral" /> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const PlaceLineItem = ({ item, index, icon = 'LocationPin', isFocused = false, showChevron }) => {
  return (
    <div className={``} key={index}>
      <InteractiveItem
        item={{
          id: item.place_id,
          label: item.structured_formatting.main_text,
          icon,
          subline: item.structured_formatting.secondary_text,
        }}
        isFocused={isFocused}
        needsChevron={showChevron}
        onClick
      />
    </div>
  );
};
export default PlaceLineItem;
