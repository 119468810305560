import React from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../../components/common/sidebar/SideBar';
import MainSection from '../../components/MainSection';

function OrderSuccessPage() {

  const { order_id } = useParams();
  return (
    <div style={{ display: 'flex' }}>
      <SideBar />
      <MainSection>
        <div className="content">
          <div className="maintext">
            {' '}
            ORDER ID:#{order_id}
            <br />
            wird sofort vom Fahrer
            <br />
            abgeholt.
          </div>

          <button 
            className="success_btn" 
            onClick={()=>{window.location.href="/orders"}}>
            Zur Bestellübersicht
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default OrderSuccessPage;
