import React, { useEffect } from 'react';

export const saveUserDataToZoho = ({ pharmacy = {} }) => {
  const userData = typeof pharmacy === 'string' ? JSON.parse(pharmacy) : pharmacy;

  if (window?.$zoho?.salesiq?.visitor) {
    if (userData?.email) {
      window.$zoho.salesiq.visitor.email(userData?.email);
    }

    if (userData?.identifier) {
      window.$zoho.salesiq.visitor.name(userData?.identifier);
    }
  }
};

function ZohoSalesIQ({ pharmacy = {} }) {
  useEffect(() => {
    // Create the script element
    const zohoScript = document.createElement('script');
    zohoScript.type = 'text/javascript';
    zohoScript.id = 'zsiqscript';
    zohoScript.defer = true;
    zohoScript.src = 'https://salesiq.zohopublic.eu/widget';

    const userData = typeof pharmacy === 'string' ? JSON.parse(pharmacy) : pharmacy;

    // Zoho SalesIQ Script
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: process.env.REACT_APP_PUBLIC_ZOHO_WIDGET_CODE,
      values: { pharmacy },
      ready: function () {
        window.$zoho.salesiq.visitor.info({ ...userData });

        saveUserDataToZoho({ pharmacy });
      },
    };

    // Append the script to the document
    document.body.appendChild(zohoScript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(zohoScript);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null; // This component does not render anything
}

export default ZohoSalesIQ;
