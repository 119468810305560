import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const api = axios.create({
  baseURL: '/api/v1',
  headers: {
    Authorization: cookies.get('token'),
  },
});

export const apiV2 = axios.create({
  baseURL: 'https://staging-v2.first-a.de/api/v2',
  headers: {
    Authorization: cookies.get('token'),
  },
});
