import React from "react";
import Cookies from "universal-cookie";
import { Redirect, Route } from "react-router-dom";

const cookies = new Cookies();
const isAuthenticated = !!cookies.get("token");

function GuardedRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated 
        ? <Component {...props} /> 
        : <Redirect to="/login" />
      }
    />
  );
}

export default GuardedRoute;
