import { api } from './api';

export const getPharmacyProfile = async () => {
  try {
    const response = await api.get(`/pharmacy/profile`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getPharmacyProducts = async ({ query, pharmacyIdentifier }) => {
  try {
    const response = await api.get(
      `/products?queryString=${query}&pharmacyIdentifier=${pharmacyIdentifier}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPharmacyOperationTime = async pharmacyIdentifier => {
  try {
    const response = await api.get(`/pharmacy/${pharmacyIdentifier}/operationalTime`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};
