import { getOrderLabel } from '../services/orders';
import isProduction from '../utils/environment';

export function getDeliveryPartnerUrl(deliveryPartner) {
  if (isProduction()) {
    return `https://d2embvd7wfffa.cloudfront.net/images/delivery-${deliveryPartner}.png`;
  } else {
    return `https://d2embvd7wfffa.cloudfront.net/images/delivery-${deliveryPartner}-staging.png`;
  }
}

export async function handleOrderLabelDownload(order) {
  const { order_id, order_id_identifier } = order;
  const data = await getOrderLabel(order_id);

  const blob = new Blob([data], { type: 'application/pdf' });
  const a = document.createElement('a');

  a.href = URL.createObjectURL(blob);
  a.download = `${order_id_identifier}.pdf`;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.click();
  a.remove();

  URL.revokeObjectURL(a.href);
}
