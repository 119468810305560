import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GuardedRoute from './GuardedRoute';
import LoginPage from '../login/LoginPage';
import HomePage from '../home/HomePage';
import OrdersPage from '../orders/OrdersPage';
import OrderItemPage from '../orders/OrderItemPage';
import OrderHistoryItemPage from '../orders/OrderHistoryItemPage';
import OrdersHistoryPage from '../orders/OrdersHistoryPage';
import BodendiestPage from '../botendienst/BotendienstPage';
import Contact from '../contact/Contact';
import OrderSuccessPage from '../orders/OrderSuccessPage';

function Routes() {
  return (
    <Router>
      <Switch>
        <GuardedRoute exact={true} path="/" component={HomePage} />
        <GuardedRoute exact={true} path="/orders" component={OrdersPage} />
        <GuardedRoute exact={true} path="/orders/history" component={OrdersHistoryPage} />
        <GuardedRoute exact={true} path="/orders/:order_id_mongo" component={OrderItemPage} />
        <GuardedRoute
          exact={true}
          path="/orders/history/:order_id_mongo"
          component={OrderHistoryItemPage}
        />
        <GuardedRoute exact={true} path="/botendienst" component={BodendiestPage} />
        <GuardedRoute exact={true} path="/orders/update" />
        <GuardedRoute exact={true} path="/contact" component={Contact} />
        <GuardedRoute exact={true} path="/orders/success/:order_id" component={OrderSuccessPage} />
        <Route exact={true} path="/login">
          <LoginPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
