const {api} = require('./api');

export const getUserByOrder = async (orderId) => {
  try {
    const response = await api.get(`/users/${orderId}`);
    return response.data;
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
}
