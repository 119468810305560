import { useListState } from '@react-stately/list';

import { mergeProps, useFocusRing, useKeyboard, useListBox, useOption } from 'react-aria';
import { useObjectRef } from '@react-aria/utils';

import { forwardRef, useRef, useState } from 'react';
import { Item } from '@react-stately/collections';
import PlaceLineItem from '../placeLineItem/PlaceLineItem';

const placePredictionToAriaProps = placePredictions => ({
  'aria-label': 'Aria label',
  children: placePredictions.map(place => <Item key={place.place_id}>Yoooo</Item>),
});

const AccessibleListBox = forwardRef(
  ({ predictions, onFocusBack, placeClickHandler, showChevron }, forwardedRef) => {
    const ariaProps = placePredictionToAriaProps(predictions);
    let [key, setKey] = useState('');
    let state = useListState({
      ...ariaProps,
      selectionMode: 'single',
      selectionBehavior: 'toggle',
      onSelectionChange: keys => {
        if (keys !== 'all') {
          const id = keys.values().next().value;
          if (!id) {
            placeClickHandler(key);
          } else {
            setKey(id);
            placeClickHandler(id);
          }
        }
      },
    });

    let ref = useObjectRef(forwardedRef);

    const { keyboardProps } = useKeyboard({
      onKeyDown(e) {
        if (e.key === 'Escape') {
          onFocusBack();
        }
        e.continuePropagation();
      },
    });

    let { listBoxProps, labelProps } = useListBox(ariaProps, state, ref);
    return (
      <ul {...mergeProps(listBoxProps, keyboardProps)} ref={ref}>
        {Array.from(state.collection).map(item =>
          item.type === 'section' ? null : (
            <Place
              placeClickHandler={placeClickHandler}
              item={predictions.find(prediction => prediction.place_id === item.key)} //check here
              node={item}
              showChevron={showChevron}
              key={item.key}
              state={state}
            />
          ),
        )}
      </ul>
    );
  },
);

function Place({ item, state, node, placeClickHandler, showChevron, handlePlaceClick }) {
  // Get props for the option element
  let ref = useRef(null);
  let { optionProps, isSelected, isDisabled } = useOption({ key: node.key }, state, ref);

  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li {...mergeProps(optionProps, focusProps)} ref={ref} className="outline-none">
      <PlaceLineItem
        icon="LocationPinHollow"
        showChevron={showChevron}
        isFocused={isFocusVisible}
        item={item}
        index={item.place_id}
        key={item.place_id}
      />
    </li>
  );
}
AccessibleListBox.displayName = 'AccessibleListBox';
export default AccessibleListBox;
