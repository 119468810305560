import React, { useEffect, useState } from 'react';
import { getDriverByOrder } from '../../../services/orders';
function Rider({ order_id }) {
  const [orderDriver, setOrderDriver] = useState();

  useEffect(async () => {
    let driver = await getDriverByOrder(order_id);
    setOrderDriver(driver);
  }, []);

  if(orderDriver) {
    return (
      <div className="rider-body">
        <div className="rider-container">
          {orderDriver && orderDriver.name && <p>RiderName: {orderDriver.name}</p>}
          {orderDriver && orderDriver.phone && <p>RiderPhone: {orderDriver.phone}</p>}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Rider;
