import React, { useRef, useEffect } from 'react';

let status_map = {
  //"not_available":"nicht leiferbar",
  out_of_stock: 'nicht auf lager',
  wrong_info: 'pharm. bedenken',
  substitute: 'Substitut beigelegt',
  available: 'Vorrätig',
  select: 'null',
};

function selectValue(status) {
  if (status == null) {
    return 'Auswählen';
  } else {
    return status_map[status];
  }
}

function selectStyle(status) {
  switch (status) {
    case 'available':
      return { backgroundColor: 'rgba(18, 230, 0, 0.46)', color: 'black' };
    case 'substitute':
      return { backgroundColor: 'orange', color: 'black' };
    case 'wrong_info':
      return { backgroundColor: 'red', color: 'white' };
    case 'out_of_stock':
      return { backgroundColor: 'red', color: 'white' };
    default:
      return {};
  }
}

const CustomDropDown = ({ index, itemStatusHandler, disabledCheck, item, deleteSubstitute }) => {
  const selectOptions = useRef(null);
  useEffect(() => {
    selectOptions.current.style.display = 'none';
  }, []);

  function dropDownToggle() {
    if (selectOptions.current.style.display === 'none') {
      selectOptions.current.style.display = 'flex';
    } else {
      selectOptions.current.style.display = 'none';
    }
  }

  return (
    <div className="custom-btn-body">
      <button
        className="status"
        style={selectStyle(item.status)}
        onClick={() => {
          dropDownToggle();
        }}
        disabled={disabledCheck()}
      >
        {selectValue(item.status)}
      </button>
      <div ref={selectOptions} className="item-body">
        <button
          className="btn-item"
          value="available"
          onClick={event => {
            dropDownToggle();
            itemStatusHandler(event, index);
            deleteSubstitute(index);
          }}
        >
          Vorrätig
        </button>
        <button
          className="btn-item"
          value="substitute"
          onClick={event => {
            dropDownToggle();
            itemStatusHandler(event, index);
          }}
        >
          Substitut beigelegt
        </button>
        <button
          className="btn-item"
          value="out_of_stock"
          onClick={event => {
            dropDownToggle();
            itemStatusHandler(event, index);
            deleteSubstitute(index);
          }}
        >
          Nicht auf Lager
        </button>
        <button
          className="btn-item"
          value="wrong_info"
          onClick={event => {
            dropDownToggle();
            itemStatusHandler(event, index);
            deleteSubstitute(index);
          }}
        >
          pharm. Bedenken
        </button>
      </div>
    </div>
  );
};

export default CustomDropDown;
