import React from 'react';
import MainHeader from '../../components/MainHeader';
import MainSection from '../../components/MainSection';
import SideBar from '../../components/common/sidebar/SideBar';
import ContactDetails from './ContactDetails';

const Contact = () => {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <SideBar />
      <MainSection>
        <MainHeader headerTitle="Notfallkontakte" />
        <ContactDetails />
      </MainSection>
    </div>
  );
};

export default Contact;
