import React, { useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { useKeyboard } from 'react-aria';
import { isSupportedCountry, isValidPhoneNumber } from 'libphonenumber-js';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  Button,
  DatePicker,
  message,
  Space,
  Form,
  Input,
  Select,
  Drawer,
  Row,
  Col,
  Typography,
} from 'antd';

import AccessibleListbox from '../accessibleListBox/AccesibleListBox';
import { createBotendienstOrder } from '../../../services/botendienst';
import {
  getAddressFromGooglePlaceResult,
  getFormattedAddressFromAddressObject,
} from '../../../utils/getAddressDetailsFromPlace';

const utc = require('dayjs/plugin/utc');
const _ = require('lodash');
const dayjs = require('dayjs');
dayjs.extend(utc);

const { Title, Text } = Typography;
const { TextArea } = Input;

const getAddressComponent = (placeDetails, type) => {
  return (
    placeDetails?.address_components?.find(component => component.types?.[0] === type)?.long_name ||
    ''
  );
};

function getError(showSuggestion, placePredictions) {
  if (placePredictions.length === 0) {
    return 'Sorry, we do not recognise this address';
  }
  if (showSuggestion === 'ERROR_STEET_NAME') {
    return 'Please indicate a street name and number';
  }
  if (showSuggestion === 'ERROR_STREET_NUMBER') {
    return 'Please indicate the street number';
  }
  return '';
}

export const getBuildingNumber = placeDetails => {
  return getAddressComponent(placeDetails, 'street_number');
};

function validatePlaceSuggestionForStreetNameAndNumber(placeresult) {
  if (!placeresult) {
    return '';
  }
  const street = getStreetName(placeresult);
  const streetNumber = getBuildingNumber(placeresult);
  if (!street) {
    return 'ERROR_STEET_NAME';
  } else if (!streetNumber) {
    return 'ERROR_STREET_NUMBER';
  }
  return '';
}

export const getStreetName = placeDetails => {
  return getAddressComponent(placeDetails, 'route');
};

const getDay = (operationalTime, day) => operationalTime[day];

const timeOptionsInitial = (isTheSameDay, currentOperationalTime) => {
  if (!currentOperationalTime) {
    return;
  }
  const { startTime, closeTime, isClosed } = currentOperationalTime;
  let timeOption = [];
  if (isClosed) {
    return timeOption;
  }
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  let startHour = parseInt(startTime.split(':')[0]);
  const endHour = parseInt(closeTime.split(':')[0]);

  const startMin = parseInt(startTime.split(':')[1]);
  if (startMin > 0) {
    startHour++;
  }

  for (let hour = startHour; hour <= endHour; hour++) {
    if (isTheSameDay) {
      if (hour < currentHour + 2) {
        timeOption.push({ value: `${hour}:00`, label: `${hour}:00`, disabled: true });
      } else {
        timeOption.push({ value: `${hour}:00`, label: `${hour}:00` });
      }
    } else {
      timeOption.push({ value: `${hour}:00`, label: `${hour}:00` });
    }
  }
  return timeOption;
};

const getProvince = placeDetails => {
  return getAddressComponent(placeDetails, 'administrative_area_level_3');
};

const BotendienstModal = ({ visible, onClose, onOrderCreated, operationalTime }) => {
  const [pharmacy, setPharmacy] = useState('');

  const [messageApi, contextHolder] = message.useMessage();
  const now = dayjs();

  useEffect(() => {
    const pharmacy = JSON.parse(localStorage.getItem('pharmacy'));
    if (pharmacy.identifier) {
      setPharmacy(pharmacy.identifier);
    }
  }, []);

  function getDateBasedOnTime() {
    const currentTime = dayjs();
    const cutoffTime = dayjs().set('hour', 16).set('minute', 59).set('second', 0);
    if (currentTime.isBefore(cutoffTime)) {
      // Current time is before 16:59, return current date
      return currentTime;
    } else {
      // Current time is after 16:59, return tomorrow's date
      const tomorrow = currentTime.add(1, 'day');
      return tomorrow;
    }
  }

  const handleGetInitialForm = () => {
    const deliveryDate = getDateBasedOnTime();
    const initialForm = {
      firstName: { value: '', error: '', isRequired: true },
      lastName: { value: '', error: '', isRequired: true },
      phone: { value: '', error: '', isRequired: true },
      address: { value: '', error: '', isRequired: true },
      deliveryDate: { value: deliveryDate, error: '', isRequired: true },
      deliveryTime: { value: '', error: '', isRequired: true },
      info: { value: '', error: '', isRequired: false },
      numberOfMedications: { value: '', error: '', isRequired: true },
    };
    return initialForm;
  };

  const [searchtermOverride, setSearchTimeOverride] = useState('');
  const [showPlacePredictions, setShowPlacePredictions] = useState(false);
  const [nowUTC, setNowUTC] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [province, setProvince] = useState('');
  const [phoneValidatedOnce, setPhoneValidatedOnce] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [showChevron, setShowChevron] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressPlaceholder, setAddressPlaceholder] = useState('');
  const [showSuggestion, setShowSuggestion] = useState('');
  const [form, setForm] = useState(handleGetInitialForm());
  const timeout = useRef();
  const [currentCountry, setCurrentCountry] = useState({
    value: '+49',
    label: 'DE',
    country: 'Germany',
    countryCode: 'DE',
  });

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 100,
  });

  const onSubmit = async () => {
    const isValidForm = validateForm();
    // Parse the input time string in the current timezone
    if (isValidForm) {
      const localTime = dayjs(form.deliveryTime.value, 'HH:mm');

      // Convert the local time to UTC
      const utcTime = localTime.utc();

      // Format the UTC time as a string (in the format HH:mm)
      const formattedUTCTime = utcTime.format('HH:mm');
      const preparedData = {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        numberOfMedications: form.numberOfMedications.value,
        phone: form.phone.value,
        postalCode: selectedAddress.postalCode,
        address1: selectedAddress.address1,
        address2: selectedAddress.address2,
        city: selectedAddress.city,
        country: selectedAddress.country,
        info: form.info.value,
        pharmacy: pharmacy,
        deliveryDate: form.deliveryDate.value,
        deliveryTime: formattedUTCTime,
        province: province,
      };
      try {
        await createBotendienstOrder(preparedData).then(res => {
          if (res.status) {
            message.success('Ihr Botenauftrag ist eingegangen');
            onOrderCreated();
            onClose();
          } else {
            message.error(res.message);
          }
        });
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    const londonTime = moment().tz('Europe/London');
    const currentTimeInLondon = londonTime.format('YYYY-MM-DD HH:mm:ss');
    setNowUTC(moment(currentTimeInLondon));
  }, []);

  const validateForm = () => {
    let isValidForm = true;
    const copyForm = _.cloneDeep(form);

    Object.entries(copyForm).forEach(field => {
      const [key, value] = field;
      if (value.isRequired && value.value === '') {
        isValidForm = false;
        switch (key) {
          case 'firstName':
            setForm(form => ({
              ...form,
              [key]: {
                ...form[key],
                error: 'Bitte geben Sie den Vornamen des Kunden/der Kundin an',
              },
            }));
            break;
          case 'lastName':
            setForm(form => ({
              ...form,
              [key]: {
                ...form[key],
                error: 'Bitte geben Sie den Nachnamen des Kunden/der Kundin an',
              },
            }));
            break;
          case 'address':
            setForm(form => ({
              ...form,
              [key]: { ...form[key], error: 'Bitte geben Sie eine vollständige Lieferadresse an' },
            }));
            break;
          case 'phone':
            setForm(form => ({
              ...form,
              [key]: {
                ...form[key],
                error: 'Bitte geben Sie die Telefonnummer des Kunden/der Kundin an',
              },
            }));
            break;
          case 'deliveryTime':
            setForm(form => ({
              ...form,
              [key]: { ...form[key], error: 'Bitte wählen Sie eine Abholzeit' },
            }));
            break;
          case 'numberOfMedications':
            setForm(form => ({
              ...form,
              [key]: {
                ...form[key],
                error: 'Bitte geben Sie eine Anzahl der Medikamente an, um fortzufahren',
              },
            }));
            break;

          default:
            break;
        }
      }
    });
    if (_.isEmpty(selectedAddress)) {
      setForm(form => ({
        ...form,
        address: { ...form.address, error: 'Bitte geben Sie eine vollständige Lieferadresse an' },
      }));
      isValidForm = false;
    }
    if (isInvalidPhone) {
      isValidForm = false;
      setForm(form => ({
        ...form,
        phone: {
          ...form.phone,
          error: 'Bitte geben Sie die Telefonnummer des Kunden/der Kundin an',
        },
      }));
    }

    return isValidForm;
  };

  const validatePhoneNumber = useCallback(
    (text, inBackground) => {
      if (isSupportedCountry(currentCountry.countryCode)) {
        if (isValidPhoneNumber(text, currentCountry.countryCode)) {
          !phoneValidatedOnce && setPhoneValidatedOnce(true);
          setPhoneInvalid(false);
          setIsInvalidPhone(false);
          setForm(form => ({ ...form, phone: { ...form.phone, error: '' } }));
        } else if (!inBackground) {
          setPhoneInvalid(true);
          !phoneValidatedOnce && setPhoneValidatedOnce(true);
          setIsInvalidPhone(true);
          setForm(form => ({
            ...form,
            phone: {
              ...form.phone,
              error: 'Bitte geben Sie die Telefonnummer des Kunden/der Kundin an',
            },
          }));
        }
      } else {
        // skip check if country is not supported by library
        !phoneValidatedOnce && setPhoneValidatedOnce(true);
        setPhoneInvalid(false);
        setForm(form => ({ ...form, phone: { ...form.phone, error: '' } }));
      }
    },
    [currentCountry.countryCode, phoneValidatedOnce],
  );

  const getAddressFromPlace = place => {
    let city, street, country, postalCode, streetNumber, address1, address2;
    place.address_components?.forEach(component => {
      if (
        component.types.includes('locality') ||
        component.types.includes('administrative_area_level_1')
      ) {
        city = component.long_name || '';
      }
      if (component.types.includes('route')) {
        street = component.long_name || '';
      }
      if (component.types.includes('country')) {
        country = component.long_name || '';
      }
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name || '';
      }
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name || '';
      }
      if (component.types.includes('street_number')) {
        address1 = component.long_name || '';
      }
      if (component.types.includes('route')) {
        address2 = component.long_name || '';
      }
    });

    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();

    return {
      city,
      street,
      country,
      postalCode,
      latitude: lat,
      longitude: lng,
      formattedAddress: `${street || ''} ${streetNumber || ''}, ${postalCode || ' '} ${
        city || ''
      }, ${country || ''}`,
      geometry: place.geometry,
      address2,
      address1,
      streetNumber,
    };
  };

  const listboxRef = useRef();

  const onPlaceSelected = async placeDetails => {
    const address = await getAddressFromGooglePlaceResult(placeDetails);
    setShowPlacePredictions(false);
    const provincelocal = getProvince(placeDetails);
    setProvince(provincelocal);
    setSelectedAddress(address);
    handleChangeForm('address', address);

    setAddressPlaceholder(getFormattedAddressFromAddressObject(address));
  };

  const localErrorToDisplay = () => {
    let errorToDisplay = '';
    if (phoneErrorMessage && phoneErrorMessage.length >= 1) {
      errorToDisplay = phoneErrorMessage;
    }
    return errorToDisplay;
  };

  const inputRef = useRef(null);
  const [timeOptions, setTimeOptions] = useState(
    timeOptionsInitial(true, getDay(operationalTime, new Date().getDay())),
  );

  function onChangeSearchTerm(text) {
    setSearchTerm(text);
    setSearchTimeOverride(text);
    getPlacePredictions({
      input: text,
      componentRestrictions: { country: 'de' },
    });
  }

  const { keyboardProps } = useKeyboard({
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        if (listboxRef && listboxRef.current) {
          listboxRef.current.focus();
        }
      }
      e.continuePropagation();
    },
  });

  async function getPlaceDetails(placeId) {
    return new Promise((resolve, reject) => {
      placesService?.getDetails(
        {
          placeId,
        },
        placeDetails => {
          if (placeDetails) {
            resolve(placeDetails);
          }
          reject();
        },
      );
    });
  }

  const handleNumberOfMedicationsChange = value => {
    /^[+0-9]*$/.test(value) && handleChangeForm('numberOfMedications', value);
  };

  const changePhoneNumber = useCallback(
    text => {
      if (/^[+0-9]*$/.test(text)) {
        handleChangeForm('phone', text);

        // 3 countries are not supported at the moment UM, PN and HM
        if (timeout.current) {
          // clear timeout if already running
          clearTimeout(timeout.current);
        }
        if (phoneInvalid || phoneValidatedOnce) {
          // if phone was invalid or was validated once
          validatePhoneNumber(text);
          return;
        }
        // validate in background on every change
        validatePhoneNumber(text, true);

        timeout.current = setTimeout(() => {
          // validate after 3 sec in case the user is inactive for 3 seconds
          validatePhoneNumber(text);
        }, 3000);
      }
    },
    [phoneInvalid, phoneValidatedOnce, validatePhoneNumber],
  );

  const placeClickHandler = async placeId => {
    const placeDetails = await getPlaceDetails(placeId);
    if (placeDetails) {
      const error = validatePlaceSuggestionForStreetNameAndNumber(placeDetails);
      if (!error) {
        setShowSuggestion('');
        onPlaceSelected(placeDetails);
      } else {
        inputRef?.current?.focus();
        inputRef?.current?.setSelectionRange(
          (placeDetails.name?.length || 0) + 1,
          (placeDetails.name?.length || 0) + 1,
        );
        setShowSuggestion(error);
      }
    }
  };

  useEffect(() => {
    const currentDate = dayjs();
    const selectedDay = dayjs(form.deliveryDate.value);

    if (selectedDay.isAfter(currentDate)) {
      setTimeOptions(timeOptionsInitial(false, getDay(operationalTime, selectedDay.day())));
    } else {
      setTimeOptions(timeOptionsInitial(true, getDay(operationalTime, selectedDay.day())));
    }
  }, []);

  const handleChangeDatePicker = event => {
    handleChangeForm('deliveryDate', moment.utc(event).format('YYYY-MM-DD'));
    handleChangeForm('deliveryTime', '');

    const today = moment();
    const selectedDate = moment.utc(event).format('YYYY-MM-DD');
    const theSameDay = today.isSame(moment(selectedDate), 'day');
    const currentDayofWeek = moment(selectedDate).day();
    if (theSameDay) {
      setTimeOptions(timeOptionsInitial(true, getDay(operationalTime, currentDayofWeek)));
    } else {
      setTimeOptions(timeOptionsInitial(false, getDay(operationalTime, currentDayofWeek)));
    }
  };

  const handleChangeForm = (field, value) => {
    setForm(prevValue => ({ ...prevValue, [field]: { ...prevValue[field], value, error: '' } }));
  };

  useEffect(() => {
    if (showPlacePredictions) {
      inputRef.current.focus();
    }
  }, [showPlacePredictions]);

  const disabledDate = current => {
    const currentDate = dayjs();
    const threeMonthsFromNow = currentDate.add(3, 'months');

    // Disable all past days
    if (current.isBefore(now, 'day')) {
      return true;
    }

    // Disable the current day if the time is greater than 17:00
    if (now.hour() >= 17 && current.isSame(now, 'day')) {
      return true;
    }
    if (current.isAfter(threeMonthsFromNow, 'day')) {
      return true;
    }
    const currentOperationalTime = getDay(operationalTime, current.day());
    if (!currentOperationalTime) {
      return true;
    }
    const { isClosed } = currentOperationalTime;
    if (isClosed) {
      return true;
    }

    return false;
  };

  return (
    <Drawer
      title={`Neuer Botenauftrag`}
      placement="right"
      size={'large'}
      onClose={onClose}
      open={visible}
      footer={
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} style={{ color: '#6366F1', border: 0, fontSize: 16 }}>
            Abbrechen
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={onSubmit}
            className="submit-order-button"
            style={{ background: '#5B71EF', padding: '12px 20px', height: 'auto' }}
          >
            Auftrag absenden
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        style={{
          maxWidth: 600,
        }}
      >
        <Title style={{ fontSize: '20px', textAlign: 'left' }}>Kundendaten</Title>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Vorname *">
              <Input
                placeholder="Vorname des Kunden"
                value={form.firstName.value}
                onChange={event => handleChangeForm('firstName', event.target.value)}
              />
              {!!form.firstName.error && (
                <Text className="error-message">{form.firstName.error}</Text>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nachname *">
              <Input
                placeholder="Nachname des Kunden"
                value={form.lastName.value}
                onChange={event => handleChangeForm('lastName', event.target.value)}
              />
              {!!form.lastName.error && (
                <Text className="error-message">{form.lastName.error}</Text>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Adresse *">
          {showPlacePredictions ? (
            <>
              <Input
                autoComplete="street-adress"
                initialText={searchtermOverride}
                onChange={event => onChangeSearchTerm(event.target.value)}
                placeholder={'Straße, Hausnummer, PLZ, Stadt'}
                ref={inputRef}
                onFocus={() => {
                  inputRef && inputRef.current && inputRef.current.focus();
                }}
                label={'Street Name with the number'}
                inputProps={keyboardProps}
              />
              {!!showSuggestion && (
                <Text className="text-sm mt-2 text-neutral/60" style={{ color: 'red' }}>
                  {getError(showSuggestion, placePredictions)}
                </Text>
              )}
              {placePredictions.length > 0 && (
                <AccessibleListbox
                  showChevron={showChevron}
                  placeClickHandler={placeClickHandler}
                  onFocusBack={() => {
                    inputRef && inputRef.current && inputRef.current.focus();
                  }}
                  ref={listboxRef}
                  predictions={placePredictions}
                />
              )}
              {!!form.address.error && <Text className="error-message">{form.address.error}</Text>}
            </>
          ) : (
            <div
              onClick={() => {
                setShowPlacePredictions(true);
              }}
            >
              <Input
                value={addressPlaceholder}
                autoComplete="street-adress"
                initialText={addressPlaceholder}
                placeholder={'Straße, Hausnummer, PLZ, Stadt'}
              />
              {!!form.address.error && <Text className="error-message">{form.address.error}</Text>}
            </div>
          )}
        </Form.Item>
        <Form.Item label="Telefonnummer *">
          <Input
            placeholder="Telefonnummer des Kunden mit Vorwahl"
            value={form.phone.value}
            onChange={event => changePhoneNumber(event.target.value)}
          />
          {!!form.phone.error && <Text className="error-message">{form.phone.error}</Text>}
        </Form.Item>
        <Title style={{ textAlign: 'left', fontSize: 20 }}>Angaben zur Lieferung</Title>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Abholdatum des Pakets *">
              <DatePicker
                onChange={(event, dateString) => {
                  handleChangeDatePicker(dateString);
                }}
                disabledDate={disabledDate}
                defaultValue={form.deliveryDate.value}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Frühestmögliche Abholzeit des Pakets *">
              <Select
                defaultValue={form.deliveryTime.value}
                value={form.deliveryTime.value}
                style={{
                  width: '100%',
                }}
                onChange={value => handleChangeForm('deliveryTime', value)}
                options={timeOptions}
              />
              {!!form.deliveryTime.error && (
                <Text className="error-message">{form.deliveryTime.error}</Text>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Anzal der medikamente *">
              <Input
                placeholder="Anzal der medikamente"
                value={form.numberOfMedications.value}
                onChange={event => handleNumberOfMedicationsChange(event.target.value)}
              />
              {!!form.numberOfMedications.error && (
                <Text className="error-message">{form.numberOfMedications.error}</Text>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Hinweis zur Lieferung">
          <TextArea
            showCount
            maxLength={100}
            style={{ height: 120, marginBottom: 24 }}
            onChange={event => handleChangeForm('info', event.target.value)}
            value={form.info.value}
            placeholder="z. B. Übergröße, Kühlware, genauere Details zur Lieferadresse, Übergabe etc."
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default BotendienstModal;
