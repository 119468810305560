import { api } from './api';

export const getOrderItems = async orderId => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getOrders = async () => {
  try {
    const response = await api.get(`/orders`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getOrderLabel = async orderId => {
  try {
    const response = await api.get(`/orders/label/${orderId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getOrdersHistory = async () => {
  try {
    const response = await api.get(`/orders/history`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const updateOrder = async updateData => {
  try {
    const response = await api.put(`/orders/update`, updateData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

export const getDriverByOrder = async orderId => {
  try {
    const response = await api.get(`/orders/driver/${orderId}`);
    return response.data;
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
};
