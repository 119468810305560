import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getOrderItems, updateOrder } from '../../services/orders';
import Address from '../../components/common/address/Address';
import Rider from '../../components/common/rider/Rider';
import OrderHeading from './OrderHeading';
import OrderItemTable from './OrderItemTable';
import { format } from 'date-fns';

function OrderItemPage(props) {
  const history = useHistory();
  let { order_id_mongo } = useParams();
  const [items, setItems] = useState([]);
  const [check, setCheck] = useState(null);
  const [pharmacyNotes, setPharmacyNotes] = useState('');
  const [firstaOrderId, setFirstaOrderId] = useState();
  const [orderIdIdentifier, setOrderIdIdentifier] = useState('');
  const [disableCheckBox, setDisableCheckBox] = useState(true);
  const [orderStatus, setOrderStatus] = useState('');
  const [prescription, setPrescription] = useState('');
  const [orderId, setOrderId] = useState('');
  const [order, setOrder] = useState(null);
  const warningPopUp = useRef(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [shouldRedirectToHome, setShouldRedirectToHome] = useState(false);

  useEffect(() => {
    if (warningPopUp && warningPopUp.current) {
      warningPopUp.current.style.display = 'none';
    }
  }, []);

  useEffect(async () => {
    let response = await getOrderItems(order_id_mongo);
    setItems(prevState => response.items);

    if (response.order) {
      setOrder(response.order);
    }
    if (response.items.length === 0) {
      setDisableCheckBox(prevState => false);
    }
    if (response.order.prescription) {
      setPrescription(prevState => response.order.prescription);
    }
    if (response.order.order_id) {
      setOrderId(prevState => response.order.order_id);
    }
    if (response.order.order_id_identifier) {
      setFirstaOrderId(response.order.order_id_identifier);
      setOrderIdIdentifier(prevState => response.order.order_id_identifier);
    } else {
      setFirstaOrderId(response.order.order_id);
      setOrderIdIdentifier(prevState => response.order.order_id);
    }
    setPharmacyNotes(prevState => response.order.pharmacy_notes);
    setOrderStatus(prevState => response.order.status);
  }, []);

  function itemStatusHandler(e, index) {
    let newItem = [...items];
    newItem[index].status = e.target.value;
    setItems(prevState => newItem);
    setStatus();
  }
  const handleDownload = () => {
    window.open(`/api/v1/orders/${orderId}/prescription`);
  };

  function handleCheck(value) {
    setCheck(value);
  }

  function setStatus() {
    setDisableCheckBox(prevState => false);
    for (let item of items) {
      if (item.status == 'Select' || item.status === null) {
        setCheck(prevState => null);
        setDisableCheckBox(prevState => true);
      }
    }
  }

  const checkSubstitute = () => {
    for (let index = 0; index < items.length; index++) {
      if (items[index].status === 'substitute') {
        if (items[index].hasOwnProperty('substitute')) {
          const { pzn, packageSize, id, name, quantity, price } = items[index].substitute;
          if (!pzn || !packageSize || !id || !name || !name) {
            return true;
          }
          if (!items[index].substitute.hasOwnProperty('quantity') || quantity === 0) {
            return '1';
          }
        } else {
          return true;
        }
      }
    }
    return false;
  };

  async function handleUpdate() {
    if (check === null) {
      if (checkSubstitute()) {
        setWarningMessage(
          'Bitte geben Sie die Informationen über das Sustitutprodukt an und ob Sie den Kunde kontaktiert haben',
        );
        warningPopUp.current.style.display = 'flex';
        return;
      }
      setWarningMessage(
        'Bitte wähle aus, ob die Produkte beigelegt wurden & du die Bestellung pharmazeutisch geprüft hast!',
      );
      warningPopUp.current.style.display = 'flex';
      return;
    } else {
      if (checkSubstitute() === '1') {
        setWarningMessage('Bitte geben Sie die Anzahl von dem Sustitutprodukt an');
        warningPopUp.current.style.display = 'flex';
        return;
      }
      if (checkSubstitute()) {
        setWarningMessage('Bitte geben Sie die Informationen über das Sustitutprodukt an');
        warningPopUp.current.style.display = 'flex';
        return;
      }
    }
    try {
      await updateOrder({
        items: items,
        order: {
          _id: order_id_mongo,
          pharmacy_notes: pharmacyNotes,
          customer_contacted: check,
        },
      });
      history.push(`/orders/success/${firstaOrderId.replace('/', '-')}`);
    } catch (e) {
      if (e && e.errorCode && e.errorCode === 'ORDER_ALREADY_REFUNDED') {
        // Display message
        setWarningMessage(
          'Diese Bestellung wurde von dem Kunden storniert und wird von dem Rider nicht abgeholt. Die Bestellung wurde aus Ihrer Inbox entfernt. Die von Ihnen gemachten Änderungen werden nicht gespeichert. Sie können die Bestellung unter “Alte Bestellungen” ansehen.',
        );
        warningPopUp.current.style.display = 'flex';
        setShouldRedirectToHome(true);
        return;
      }
    }
  }

  async function handlePharmacyNotes(e) {
    let newNotes = pharmacyNotes;
    newNotes = e.target.value;
    setPharmacyNotes(prevState => newNotes);
  }

  function Warning() {
    return (
      <div className="warning-background">
        <div className="warning-body">
          <p className="warning-title">
            Bitte wähle aus, ob die Produkte beigelegt wurden & du die Bestellung pharmazeutisch
            geprüft hast!
          </p>
          <button
            className="warning-button"
            onClick={() => {
              warningPopUp.current.style.display = 'none';
            }}
          >
            Alles klar!
          </button>
        </div>
      </div>
    );
  }

  /**
   * @author Shubham Dubey
   * @description Add Substitute
   * @param index
   * @param sub
   */
  function addSubstitute(index, sub) {
    let newItem = [...items];
    newItem[index].substitute = sub;
    setItems(prevState => newItem);
  }

  /**
   * @author Shubham Dubey
   * @description Delete Substitute
   * @param index
   * @param sub
   */
  function deleteSubstitute(index) {
    let newItem = [...items];
    delete newItem[index]['substitute'];
    setItems(prevState => newItem);
  }

  return (
    <div>
      <div ref={warningPopUp} className="warning-background">
        <div className="warning-body">
          <h6 className="warning-title">{warningMessage}</h6>
          <button
            className="warning-button"
            onClick={() => {
              warningPopUp.current.style.display = 'none';
              setWarningMessage('');
              if (shouldRedirectToHome) {
                history.push('/');
              }
            }}
          >
            Alles klar!
          </button>
        </div>
      </div>

      <OrderHeading orderIdIdentifier={orderIdIdentifier} />
      <br />
      <Address order={order} order_id={order_id_mongo} />
      <Rider order_id={order_id_mongo} />
      <br />
      <div className="btn-container">
        {prescription && (
          <button onClick={handleDownload} target="_blank" className="btn-item">
            Rezept herunterladen
          </button>
        )}
      </div>
      <div>
        <OrderItemTable
          itemStatusHandler={itemStatusHandler}
          items={items}
          orderId={order_id_mongo}
          status={orderStatus}
          addSubstitute={addSubstitute}
          deleteSubstitute={deleteSubstitute}
        />
      </div>

      <br />
      <div className="comment">
        <textarea
          className="comment-input-box"
          type="text"
          value={pharmacyNotes}
          placeholder="Anmerkungen an GoPuls:"
          onChange={handlePharmacyNotes}
        />
      </div>
      <br />
      <div className="confirmation-body">
        <div className="confirmation-container row  middle-md center-md">
          <label className="form-control mr-10">Kunde wurde von uns kontaktiert</label>
          <span></span>
          <div className="form-control mr-10">
            <input
              type="checkbox"
              disabled={disableCheckBox}
              onChange={() => handleCheck(true)}
              checked={check}
              name="checkbox"
              className="confirmation-checkbox"
            />
            <label>Ja</label>
          </div>
          <span></span>{' '}
          <div className="form-control">
            <input
              type="checkbox"
              disabled={disableCheckBox}
              onChange={() => handleCheck(false)}
              checked={check === false}
              name="checkbox"
              className="confirmation-checkbox"
            />
            <label>Nein</label>
          </div>
          {/* <input
            type="checkbox"
            disabled={disableCheckBox}
            onChange={handleCheck}
            checked={check}
          ></input>
          <span>Ich habe die Bestellung pharmazeutisch geprüft.</span> */}
        </div>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <button className="submit_btn" onClick={handleUpdate}>
          Pharmazeutisch geprüft und gepackt
        </button>
      </div>
    </div>
  );
}

export default OrderItemPage;
